// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: FooterMobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import IconListManager from "../../Assets/AppTheme/appIcons";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import MenuOptionDialog from "./menuDialogBox";
import "./footer.css";
import appColors from "../../Assets/AppTheme/appColors";
import { ReactComponent as Home } from "../../Assets/newMobileIcon/home.svg";
import FeatureDiscoveryPrompt from "../FeatureDiscoveryPrompt/FeatureDiscoveryPrompt";
import { Box } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ScanIcon from "../../Assets/newMobileIcon/bottomFooter/SCAN1";
import BookHomeIcon from "../../Assets/newMobileIcon/bottomFooter/bookHome";
import OrderIcon from "../../Assets/newMobileIcon/bottomFooter/Order";
import SubcribeIcon from "../../Assets/newMobileIcon/bottomFooter/Subscriptions";
import MenuIcon from "../../Assets/newMobileIcon/bottomFooter/MENU";
import ReferandEarn from "../../Assets/newMobileIcon/bottomFooter/REFER";
import TransactionIcon from "../../Assets/newMobileIcon/bottomFooter/Transactions";
import BackIcon from "../../Assets/newMobileIcon/bottomFooter/backIcon";
import Reordericon from "../../Assets/newMobileIcon/bottomFooter/Reorder";

export default function FooterAppBarMobile(props) {
  const { pathname } = useLocation();
  const pageTitle = pathname.split("/dashboard/");
  const [activeIndex, setActiveIndex] = useState(2);

  // Define routes with visibility control
  const iconConfig = [
    {
      path: "/mobile",
      label: "Home",
      icon: pageTitle[0] !== "/mobile" ? BackIcon : BookHomeIcon,
      activeIndex: 1,
      visibleOnRoutes: ["*"], // Always visible
    },
    {
      path: "/dashboard/booknow",
      label: "Order",
      icon: OrderIcon,
      activeIndex: 2,
      visibleOnRoutes: ["*"],
    },
    {
      path: "/dashboard/subscriptionList",
      label: "Subscribe",
      icon: SubcribeIcon,
      activeIndex: 3,
      visibleOnRoutes: [
        "/dashboard/referandearn",
        "/dashboard/menu",
        "/mobile",
        "/dashboard/settings",
        "/dashboard/myaccounts",
        "/dashboard/deposit",
        "/dashboard/transactions",
        "/dashboard/support",
        "/dashboard/legal",
        "/dashboard/subscriptionList",
        "/dashboard/booknow",
        "/dashboard/myorders",
      ],
      prompt: {
        componentState: "bookWater",
        triggerState: "scanQR",
        title: "Subscribe Your Order",
        description: "Let us plan your water delivery schedule for you.",
        subdescription:
          "Your cans can be delivered on specific days of the week.",
        subdescription1: "Subscribe and get your orders booked automatically.",
      },
    },
    {
      path: "/dashboard/subscribe",
      label: "Subscribe",
      icon: SubcribeIcon,
      activeIndex: 3,
      visibleOnRoutes: ["/dashboard/subscribe"],
    },
    {
      path: "/dashboard/referandearn",
      label: "Refer",
      icon: ReferandEarn,
      activeIndex: 4,
      visibleOnRoutes: [
        "/dashboard/referandearn",
        "/dashboard/menu",
        "/mobile",
        "/dashboard/settings",
        "/dashboard/myaccounts",
        "/dashboard/deposit",
        "/dashboard/transactions",
        "/dashboard/support",
        "/dashboard/legal",
      ],
      prompt: {
        componentState: "referAndEarn",
        triggerState: "menu",
        title: "Refer & Earn",
        description: "Water is essential for life, and so are friends.",
        subdescription: "You can gift safe and pure water to your friends now.",
        subdescription1:
          "Refer a friend to the BookWater app and earn rewards for yourself and your friend!.",
      },
    },
    {
      path: "/dashboard/qrscan",
      label: "Scan",
      icon: ScanIcon,
      activeIndex: 5,
      visibleOnRoutes: [
        "/dashboard/qrscan",
        "/mobile",
        "/dashboard/referandearn",
        "/dashboard/settings",
        "/dashboard/myaccounts",
        "/dashboard/deposit",
        "/dashboard/transactions",
        "/dashboard/support",
        "/dashboard/legal",
      ],
      prompt: {
        componentState: "scanQR",
        triggerState: "wallet",
        title: "Scan QR",
        description:
          "Now you know! Unlock the secrets of your BookWater can with a simple scan of the QR code on your can! ",
        subdescription:
          "Stay informed on quality parameters of both, the water you are drinking and the can it is stored in.",
        subdescription1:
          "Get usage insights and water quality reports using the QR code.",
      },
    },
    {
      path: "/dashboard/menu",
      label: "Menu",
      icon: MenuIcon,
      activeIndex: 6,
      visibleOnRoutes: ["*"],
      prompt: {
        componentState: "menu",
        triggerState: "address",
        title: "Menu",
        description:
          "Click on the Menu to access Subscription, Your Profile, Your Transactions and current and past Order details",
        subdescription: "",
        subdescription1: "",
      },
    },
    {
      path: "/dashboard/myorders",
      label: "Reorder",
      icon: Reordericon,
      activeIndex: 7,
      visibleOnRoutes: [
        "/dashboard/booknow",
        "/dashboard/myorders",
        "/dashboard/subscriptionList",
        "/dashboard/subscribe",
      ],
    },
  ];

  // Update active index based on current route
  useEffect(() => {
    const currentConfig = iconConfig.find((config) =>
      pathname.includes(config.path)
    );
    setActiveIndex(currentConfig ? currentConfig.activeIndex : 6);
  }, [pathname]);

  const visibleIcons = iconConfig
    .filter(
      (config) =>
        config.visibleOnRoutes.includes("*") ||
        config.visibleOnRoutes.includes(pathname)
    )
    .sort((a, b) => {
      if (a.activeIndex === 1) return -1;
      if (b.activeIndex === 1) return 1;
      if (a.activeIndex === activeIndex && a.activeIndex !== 6) return -1;
      if (b.activeIndex === activeIndex && b.activeIndex !== 6) return 1;
      if (a.activeIndex === 6) return 1;
      if (b.activeIndex === 6) return -1;
      return a.activeIndex - b.activeIndex;
    });

  return (
    <>
      <Box className="navigation">
        <ul className="unorderList">
          {visibleIcons.map((config) => {
            const isActive = activeIndex === config.activeIndex;
            const IconComponent = config.icon;
            return (
              <>
                <Link to={config.path} key={config.label}>
                  <li className="list">
                    <a href="#">
                      <FeatureDiscoveryPrompt
                        setmenuDialogOpean=""
                        setPromptStates={props.setPromptStates}
                        open={
                          props.promptStates?.[config.prompt?.componentState] ||
                          false
                        }
                        backgroundColor={appColors.textColorNew}
                        componentState={config.prompt?.componentState}
                        triggerState={config.prompt?.triggerState}
                        title={config.prompt?.title}
                        description={config.prompt?.description}
                        subdescription={config.prompt?.subdescription}
                        subdescription1={config.prompt?.subdescription1}
                      >
                        <span className="icon">
                          <IconComponent
                            stroke={
                              isActive
                                ? appColors.textColorNew
                                : appColors.darkGray
                            }
                          />
                        </span>
                      </FeatureDiscoveryPrompt>
                      <span
                        className="text"
                        style={{
                          color: isActive
                            ? appColors.textColorNew
                            : appColors.darkGray,
                        }}
                      >
                        {config.label}
                      </span>
                    </a>
                  </li>
                </Link>
                {pageTitle[0] !== "/mobile" && (
                  <div className="divider" aria-hidden="true"></div>
                )}
              </>
            );
          })}
        </ul>
      </Box>
      <MenuOptionDialog
        open={props.menuDialogOpean}
        onClose={() => props.setmenuDialogOpean(false)}
        promptStates={props.promptStates}
        setPromptStates={props.setPromptStates}
      />
    </>
  );
}
