// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: productSelect.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Mobile Landing page which handel booking process and redirect to checkout page
 * @copyright Bookwater tech pvt ltd
 */

import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import appColors from "../../Assets/AppTheme/appColors";
import canImage from "../../Assets/Images/WaterCanImg.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function HomeProductSelection() {
  const history = useHistory();
  //function to redirct to booking page
  const handelBookNowPage = () => {
    history.replace("/dashboard/booknow");
  };

  //function to redirct to booking page
  const handelSubscribePage = () => {
    history.replace("/dashboard/subscriptionList");
  };
  return (
    <>
      <Grid container spacing={2} rowGap={2} sx={styles.mainBox}>
        <Grid item xs={6}>
          <Card sx={styles.cardStyles}>
            <Typography sx={styles.typograpy}>Book Now</Typography>
            <Typography sx={styles.typograpy1}>
              Quality water for you
            </Typography>
            <Box sx={styles.offerTypeBox}>
              <Typography sx={styles.typograpy2}>Upto 40% offer</Typography>
            </Box>
            <Box sx={styles.orderButtonBox}>
              <Box>
                <Button
                  size="small"
                  onClick={handelBookNowPage}
                  variant="contained"
                  sx={styles.buttonColor}
                >
                  BookNow
                </Button>
              </Box>
              <img
                alt="BookWater"
                src={canImage}
                style={{ height: "4rem", width: "4rem" }}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={styles.cardStyles}>
            <Typography sx={styles.typograpy}>Subscription</Typography>
            <Typography sx={styles.typograpy1}>Schedule your order</Typography>
            <Box sx={styles.offerTypeBox}>
              <Typography sx={styles.typograpy2}>Upto 40% offer</Typography>
            </Box>
            <Box sx={styles.orderButtonBox} onClick={handelSubscribePage}>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  sx={styles.buttonColor}
                >
                  Subscribe
                </Button>
              </Box>
              <img
                alt="BookWater"
                src={canImage}
                style={{ height: "4rem", width: "4rem" }}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const styles = {
  cardStyles: {
    width: "100%",
    boxShadow: 2,
    padding: "0 .5rem .5rem .5rem",
    background: appColors.cardColor,
  },
  mainBox: {
    marginTop: "1rem",
    marginBottom: ".5rem",
  },
  typograpy: {
    color: appColors.darkGray,
    fontSize: "1.4rem",
    fontWeight: "bold",
  },
  typograpy1: {
    color: appColors.Textgray,
    fontSize: "1rem",
  },
  typograpy2: {
    color: appColors.white,
    fontSize: ".8rem",
  },
  offerTypeBox: {
    borderRadius: "1rem",
    marginTop: ".2rem",
    padding: ".2rem",
    background:
      "linear-gradient(45deg, rgba(3,142,184,1) 25%, rgba(255, 255, 255, 1) 100%)",
  },
  orderButtonBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: ".3rem",
    alignItems: "center",
  },
  buttonColor: { background: appColors.textColorNew },
};
